import { createIntl, createIntlCache } from 'react-intl'

import enUS from '../locales/en-US.json'
import jaJP from '../locales/ja-JP.json'
import zhTW from '../locales/zh-TW.json'

const messages = {
    "zh-CN": zhTW,
    "zh-TW": zhTW,
    "en-US": enUS,
    "ja-JP": jaJP,
}

const LANGUAGE = {
    "zh-hans": "zh-CN",
    "zh-hant": "zh-TW",
    "en": "en-US",
    "ja": "ja-JP",
}

const cache = createIntlCache()

const errCode = {
    "0": "err_code_0",//"成功",
    "-1": "err_code_-1", //"执行失败,请重试",
    "3001": "err_code_3001", //"获取用户信息失败",
    "3002": "err_code_3002", //"用户信息不存在错误",
    "3003": "err_code_3003", //"列表数据查找失败",
    "3004": "err_code_3004", //"参数不全",
    "3005": "err_code_3005", //"当前条数据不存在",
    "3006": "err_code_3006", //"请求错误",
    "3007": "err_code_3007", //"解析转换错误",
    "3009": "err_code_3009", // 快速访问达到上限，无法添加
    "203": "err_code_203", //"未完全成功",
    "401": "err_code_401", //"未登录",
    "403": "err_code_403", //"未授权访问",
    "404": "err_code_404", //"资源未找到",
    "40002": "err_code_40002", //"上传出错",
    "40001": "err_code_40001", //"凭证无效",
    "40003": "err_code_40003", //"目录创建失败",
    "40004": "err_code_40004", //"对象已存在",
    "40005": "err_code_40005", //"签名过期",
    "40006": "err_code_40006", //"当前存储策略不允许",
    "40007": "err_code_40007", //"用户组无法进行此操作",
    "40008": "err_code_40008", //"非管理用户组",
    "40009": "err_code_40009", // 上传的文件格式不支持
    "50001": "err_code_50001", //"数据库操作失败",
    "50002": "err_code_50002", //"加密失败",
    "50004": "err_code_50004", //"IO操作失败",
    "50005": "err_code_50005", //"内部设置参数错误",
    "50006": "err_code_50006", //"缓存操作失败",
    "50007": "err_code_50007", //"回调失败",
}

export const getErrMsg = (code) => {
    const intl = createIntl({
        locale: window.react_intl_local,
        messages: messages[window.react_intl_local]
    }, cache)

    let intlId = ''
    if (errCode[code]) {
        intlId = errCode[code]
    } else {
        intlId = errCode[-1]
    }

    return intl.formatMessage({ id: intlId })
}