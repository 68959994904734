import React, {
    Suspense,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { withStyles, Typography, Button, IconButton } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router";
import { withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import { registerMicroApps, start } from "qiankun";
import Draggable from "react-draggable";

import { useFavicon } from "../../hooks/useFavicon";
import { toggleSnackbar, changeLanguageMethod } from "../../actions";
import { vhCheck, getNavigatorLanguage } from "../../utils";
import { formatLocalTime } from "../../utils/datetime";
import API, { baseURL, addUrlToken } from "../../middleware/Api";
import Auth from "../../middleware/Auth";
import { isPreviewable, mediaType } from "../../config";

import { SERVER_LANG } from "../../const/global";
import PageLoading from "../../component/Placeholder/PageLoading.js";
import PageNotAuth from "../../component/Placeholder/PageNotAuth";

import * as isMobileJs from "ismobilejs";

import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

import { LazyLoadImage } from "react-lazy-load-image-component";
import DocViewer from "../../component/Viewer/Doc";
import VideoPreview from "../../component/Viewer/Video.js";
import TextViewer from "../../component/Viewer/Text";
import CodeViewer from "../../component/Viewer/Code";
import classNames from "classnames";
const PDFViewer = React.lazy(() =>
    import(/* webpackChunkName: "pdf" */ "../../component/Viewer/PDF")
);

const HEADER_MODE = {
    NORMAL: "normal",
    SIMPLE: "simple",
    TOGGLE: "toggle",
};

const isMobileDevice =
    isMobileJs.default().phone || isMobileJs.default().tablet;

vhCheck();

const useStyles = makeStyles((theme) =>
    createStyles({
        box: {
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
        },
        headerWrap: {},
        headerSimple: {
            "& .header___2ZAQi": {
                display: "inline-block",
                position: "absolute",
                top: 0,
                right: 150,
                width: "auto",
                height: "auto",
                zIndex: 1,
                padding: 0,
                "& .user___1bxOY .ant-space-horizontal": {
                    gap: "0 !important",
                },
            },
            "& .sider___2XHPM,.search-icon___3FTNo,.more-btn___1APE3": {
                display: "none !important",
            },
            "& .ant-space-item-split, .ant-space-item-split~div": {
                display: "none !important",
            },
        },
        headerToggle: {
            position: "relative",
            display: "inline-block",

            "& .header___2ZAQi": {
                padding: "6px 10px",
                borderBottom: "none !important",

                "& .user___1bxOY .ant-space-horizontal": {
                    gap: "10px !important",
                },
            },
            "& .sider___2XHPM,.search-icon___3FTNo,.more-btn___1APE3": {
                display: "none !important",
            },
        },
        headerToggleUnfold: {
            "& .header___2ZAQi": {
                paddingRight: 24,
            },
        },
        commonHeaderWrap: {
            display: "flex",
            position: "relative",
        },
        commonHeader: {
            flex: 1,
        },
        commonHeaderDrag: {
            position: "absolute",
            right: 150,
            display: "flex",
            alignItems: "center",
            backgroundColor: "#FFF",
        },
        draghandle: {
            display: "flex",
            cursor: "move",
            padding: "3px 6px",
            borderRight: "1px solid rgba(0,0,0,0.1)",
        },
        togglehandle: {
            margin: "0 6px",
            padding: "6px 0",
            transform: "rotate(90deg)",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
        },
        toggleClose: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: "rotate(270deg)",
            borderTop: "1px solid #d6dce6",
        },
        mainWrap: {
            flex: 1,
            overflowY: "auto",
        },
        fileContentWrap: {
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
        },
        contentColumn: {
            flexDirection: "column",
            gap: 15,
        },
    })
);

const isIFrame = window.self !== window.top;

const CommonHeader = ({ fileInfo, downloadFile }) => {
    const classes = useStyles();
    const intl = useIntl();

    const [headerMode, setHeaderMode] = useState(
        isIFrame ? HEADER_MODE.SIMPLE : HEADER_MODE.NORMAL
    );
    const [toggleOpen, setToggleOpen] = useState(true);

    const isHeaderToggleModa = useMemo(() => {
        return headerMode === HEADER_MODE.TOGGLE && toggleOpen;
    }, [headerMode, toggleOpen]);

    const resetPageTitle = () => {
        if (!fileInfo) return;
        document.title = `${fileInfo.name || ""} | Felo Drive`;
    };

    const setHeaderDrag = () => {
        setHeaderMode(HEADER_MODE.TOGGLE);
    };

    const initHeader = () => {
        if (!fileInfo) return;

        const panelUrlReg = /(panel|local).*?\.felo.me/; // panel 测试环境或者 local 本地开发环境

        const isInIframe = () => {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        };
        const isInPanel = () => {
            if (isInIframe()) {
                return panelUrlReg.test(document.referrer);
            }
            return false;
        };
        const refreshToken = (rToken, callback) => {
            // v5 新版 api 没有独立的 refresh token 接口，只需要再次调用登录即刷新
            window.FeloSDK.login({
                anonymously: true,
            })
                .then(async (teamList) => {
                    let access_token = "";
                    const teamId = Auth.GetSessionTeamId();
                    const currentTeamInfo = teamId
                        ? teamList.filter(
                              (item) => item.team.team_id === teamId
                          )[0]
                        : teamList[0];

                    if (currentTeamInfo) {
                        // const { profile } = currentTeamInfo.account_info;
                        // profile.lang &&
                        //     changeLanguageMethod(SERVER_LANG[profile.lang]); //会导致再刷新一次

                        access_token = currentTeamInfo.login_info.access_token;
                    } else {
                        const rsp = await window.FeloSDK.loginAnonymous();
                        access_token = rsp.access_token;
                    }

                    if (callback) callback(access_token);
                })
                .catch((err) => {
                    if (window.location.href.includes("/login")) {
                        // to do
                    } else {
                        // loginFelo();
                    }
                    console.error("refreshToken error", err);
                    if (callback) callback("");
                });
        };

        const getToken = (callback, forceRefresh = false) => {
            let access_token = "",
                expires_in = 0;
            const refresh_token = "";

            const userInfo = Auth.GetCurrentLoginUser();
            if (userInfo) {
                access_token = userInfo.access_token;
                expires_in = userInfo.expires_in;
            }
            if (expires_in || !userInfo) {
                // 获取token过期了的时候就刷新页面
                const nowTime = new Date().getTime();
                if (
                    forceRefresh ||
                    parseInt(expires_in, 10) - nowTime < 300000
                ) {
                    refreshToken(refresh_token, callback);
                } else {
                    if (callback) callback(access_token);
                }
            } else {
                if (callback) callback(access_token);
            }

            return {
                access_token,
                refresh_token,
                expires_in,
            };
        };
        const WrapFeloSDK = (
            config = {
                redirectUrl: window.location.href,
            },
            teamId,
            cb
        ) => {
            const newRedirectUrl = config.redirectUrl;

            window.FeloSDK.login({
                ...config,
                ...{ redirectUrl: newRedirectUrl },
            }).then((teamList) => {
                // 已登录组织列表不为空
                if (teamList.length) {
                    localStorage.setItem("teams", JSON.stringify(teamList));

                    const localTeamId = Auth.GetSessionTeamId();
                    // 如果没有设置 team_id 则默认使用已登录组织列表中第一个组织
                    const currentTeam =
                        teamId ||
                        teamList.find(
                            (team) =>
                                team.team.team_id.toString() === localTeamId
                        )?.team.team_id ||
                        teamList[0].team;

                    Auth.SetCurrentLoginUser(currentTeam);
                    Auth.SetSessionTeamId(JSON.stringify(currentTeam.team_id));
                    Auth.SetLoginToken(
                        `${currentTeam.token_type} ${currentTeam.access_token}`
                    );

                    // 用户更新了登录组织列表，再次校验当前组织是否存在于新的组织中
                    window.FeloSDK.onTeamChange((newTeamInfo) => {
                        Auth.SetCurrentLoginUser(newTeamInfo.account_info);
                        Auth.SetSessionTeamId(
                            JSON.stringify(newTeamInfo.team.team_id)
                        );
                        const { login_info } = newTeamInfo;
                        Auth.SetLoginToken(
                            `${login_info.token_type} ${login_info.access_token}`
                        );
                    });
                }
                if (cb) cb(teamList);

                // 兜底跳转到回调地址，如果前面执行完都还在原地则进行跳转
                const redirectUrl = decodeURIComponent(
                    config?.redirectUrl ?? "/"
                );

                setTimeout(() => {
                    history.push(
                        redirectUrl.replace(window.location.origin, "")
                    );
                }, 100);
            });
        };
        const onRemove = (id) => {
            return new Promise((resolve, reject) => {
                API.delete("/object", {
                    data: {
                        items: [fileInfo.id],
                        dirs: [],
                    },
                })
                    .then(() => {
                        resolve(() => {
                            // 客户端内删除文档后回到 Drive
                            const isClient = /felo/gi.test(
                                window.navigator.userAgent
                            );
                            if (isClient) {
                                if (window.webViewWillGoBack)
                                    window.webViewWillGoBack();
                            } else {
                                history.push("/home");
                            }
                        });
                    })
                    .catch((error) => {
                        toggleSnackbar("top", "right", error.message, "error");
                    });
            });
        };
        const onShareUpdate = () => {
            API.post("/sel_share", {
                run_type: 2,
                fid_ps: fileInfo.id,
            }).then((rsp) => {
                console.log("onShareUpdate rsp", rsp);
            });
        };

        const menus = [
            {
                key: "copy",
            },
            {
                key: "download",
                title: intl.formatMessage({ id: "download" }),
                onClick: downloadFile,
            },
            {
                key: "remove",
                disabled: !(fileInfo.power >= 3),
            },
        ];

        const entry =
            process.env.SDK_ENV === "production"
                ? "https://common-header.felo.me"
                : "https://common-dev-header.felo.me";
        const appId =
            process.env.SDK_ENV === "production"
                ? "yd03A23FA124BB4A14BC9919CC37D89E9F"
                : "ydCDD0A2ADBACD417799CDC6572916E0F1";

        const HeaderApp = [
            {
                name: "Header App",
                entry: entry, // "http://local.felo.me:8000/"
                container: "#common_header",
                activeRule: "/file/:id", // 激活规则
                props: {
                    appId: appId,
                    name: "header",
                    objectId: fileInfo.id,
                    objectType: "cloudDocument",
                    title: fileInfo.name,
                    docType: "file",
                    getToken: () => {
                        return new Promise((resolve) => {
                            getToken(resolve);
                        });
                    },
                    // onLoad: () => {}, // 加载完后
                    menus: menus,
                    // members: {}, // 协同
                    // translate: {}, // 翻译
                    // notifications: {}, // 通知
                    // newMenus: {}, // 建立副本，新文档
                    // saveState: { // 需求暂时不显示时间
                    //     saving: false,
                    //     saveTime: formatLocalTime(
                    //         fileInfo.UpdatedAt,
                    //         "YYYY-MM-DD HH:mm:ss"
                    //     ),
                    // },
                    isInPanel: isInPanel(),
                    onEdit: () => {
                        WrapFeloSDK({
                            redirectUrl: window.location.href,
                            isPopup: isMobileDevice ? false : true,
                        });
                    },
                    onlyEnabledLinkShare: false,
                    onRemove: onRemove,
                    onShareUpdate: onShareUpdate,
                },
            },
        ];
        registerMicroApps(HeaderApp);
        start();
    };

    useLayoutEffect(() => {
        if (!fileInfo) return;
        switch (isPreviewable(fileInfo.name)) {
            case "msDoc": {
                setHeaderDrag();
            }
        }

        initHeader();
        resetPageTitle();
    }, [fileInfo]);

    return (
        <section
            className={classNames(classes.headerWrap, {
                [classes.headerSimple]: headerMode === HEADER_MODE.SIMPLE,
                [classes.headerToggle]: isHeaderToggleModa,
                [classes.headerToggleUnfold]:
                    headerMode === HEADER_MODE.TOGGLE && !toggleOpen,
            })}
        >
            <Draggable axis={isHeaderToggleModa ? "both" : "none"}>
                <div
                    className={`${classes.commonHeaderWrap} ${
                        isHeaderToggleModa ? classes.commonHeaderDrag : ""
                    }`}
                >
                    {isHeaderToggleModa && (
                        <div className={`${classes.draghandle} dragHandle`}>
                            <DragIndicatorIcon />
                        </div>
                    )}
                    <div
                        className={`${classes.commonHeader}`}
                        id="common_header"
                    ></div>
                    {headerMode === HEADER_MODE.TOGGLE && (
                        <div
                            className={`${classes.togglehandle} ${
                                !toggleOpen ? classes.toggleClose : ""
                            }`}
                        >
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setToggleOpen(!toggleOpen);
                                }}
                            >
                                <DoubleArrowIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            </Draggable>
        </section>
    );
};

const PublicSharedFileCompoment = (props) => {
    const { toggleSnackbar } = props;
    const classes = useStyles();
    const intl = useIntl();
    const { id } = useParams();
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const [fileInfo, setFileInfo] = useState();
    const [isNotAuth, setIsNotAuth] = useState(false);

    const getFileInfo = () => {
        API.get("/open/info/" + id)
            .then((response) => {
                const fileInfoRsp = response.data;
                if (fileInfoRsp) {
                    setFileInfo(fileInfoRsp);
                } else {
                    const { msg } = response.rawData;
                    if (msg === "No permission") {
                        setIsNotAuth(true);
                    }
                }
            })
            .catch((error) => {
                if (error.code === 404) {
                    setFileInfo(null);
                } else {
                    toggleSnackbar("top", "right", error.message, "error");
                }
            });
    };

    const getFileFaviconUrl = () => {
        const fileSuffix = fileInfo.name
            ? fileInfo.name.split(".").pop().toLowerCase()
            : "";
        let fileType = "file";
        Object.keys(mediaType).forEach((k) => {
            if (mediaType[k].indexOf(fileSuffix) !== -1) {
                fileType = k;
            }
        });

        const faviconUrlBase = `/static/img/`;
        let faviconUrlType = "";
        switch (fileType) {
            case "sheet":
                faviconUrlType = "sheet";
                break;
            case "image":
                faviconUrlType = "png";
                break;
            case "pdf":
                faviconUrlType = "pdf";
                break;
            case "word":
                faviconUrlType = "word";
                break;
            case "ppt":
                faviconUrlType = "ppt";
                break;
            case "excel":
                faviconUrlType = "excel";
                break;
            case "file":
                faviconUrlType = "other";
                break;
            case "zip":
                faviconUrlType = "archive";
                break;
            default:
                faviconUrlType = "other";
                break;
        }
        return faviconUrlBase + faviconUrlType + ".png";
    };

    const downloadFile = () => {
        API.put("/open/download/" + fileInfo.id)
            .then((response) => {
                window.location.assign(response.data);
            })
            .catch((error) => {
                toggleSnackbar("top", "right", error.message, "error");
            });
    };

    const FileSection = () => {
        const faviconUrl = getFileFaviconUrl();
        useFavicon(faviconUrl);

        switch (isPreviewable(fileInfo.name)) {
            case "img": {
                return (
                    <section className={classes.fileContentWrap}>
                        <div>
                            <LazyLoadImage
                                src={addUrlToken(
                                    baseURL +
                                        "/open/file/preview/" +
                                        fileInfo.id
                                )}
                                alt={fileInfo.name}
                            />
                        </div>
                    </section>
                );
            }
            case "msDoc": {
                const p = query.get("p");
                const id = query.get("id");
                if (!p || !id) {
                    !p && query.set("p", `/${fileInfo.name}`);
                    !id && query.set("id", fileInfo.id);
                    history.push({ search: query.toString() });
                    return null;
                }
                return (
                    <Suspense fallback={<PageLoading />}>
                        <DocViewer />
                    </Suspense>
                );
            }
            case "audio": {
                return (
                    <section className={classes.fileContentWrap}>
                        <audio
                            controls
                            src={addUrlToken(
                                baseURL + "/open/file/preview/" + fileInfo.id
                            )}
                        />
                    </section>
                );
            }
            case "video": {
                const p = query.get("p");
                const id = query.get("id");
                if (!p || !id) {
                    !p && query.set("p", `/${fileInfo.name}`);
                    !id && query.set("id", fileInfo.id);
                    history.push({ search: query.toString() });
                    return null;
                }
                return (
                    <section className={classes.fileContentWrap}>
                        <VideoPreview />
                    </section>
                );
            }
            case "edit": {
                const p = query.get("p");
                const id = query.get("id");
                if (!p || !id) {
                    !p && query.set("p", `/${fileInfo.name}`);
                    !id && query.set("id", fileInfo.id);
                    history.push({ search: query.toString() });
                    return null;
                }
                return (
                    <section className={classes.fileContentWrap}>
                        <TextViewer />
                    </section>
                );
            }
            case "pdf": {
                const p = query.get("p");
                const id = query.get("id");
                if (!p || !id) {
                    !p && query.set("p", `/${fileInfo.name}`);
                    !id && query.set("id", fileInfo.id);
                    history.push({ search: query.toString() });
                    return null;
                }

                return (
                    <Suspense fallback={<PageLoading />}>
                        <PDFViewer />
                    </Suspense>
                );
            }
            case "code": {
                const p = query.get("p");
                const id = query.get("id");
                if (!p || !id) {
                    !p && query.set("p", `/${fileInfo.name}`);
                    !id && query.set("id", fileInfo.id);
                    history.push({ search: query.toString() });
                    return null;
                }

                return (
                    <section className={classes.fileContentWrap}>
                        <CodeViewer />
                    </section>
                );
            }
            default:
                toggleSnackbar(
                    "top",
                    "right",
                    intl.formatMessage({
                        id: "can.not.preview.file.type",
                    }),
                    "warning"
                );
                return (
                    <section
                        className={classNames(
                            classes.fileContentWrap,
                            classes.contentColumn
                        )}
                    >
                        <Typography variant="h6" component="h6">
                            {intl.formatMessage({
                                id: "can.not.preview.file.type",
                            })}
                        </Typography>
                        <Button
                            onClick={downloadFile}
                            variant="contained"
                            color="primary"
                            disableElevation
                        >
                            {intl.formatMessage({ id: "download" })}
                        </Button>
                    </section>
                );
        }
    };

    useLayoutEffect(() => {
        getFileInfo();
    }, []);

    return (
        <section className={classes.box}>
            <CommonHeader fileInfo={fileInfo} downloadFile={downloadFile} />
            <section className={classes.mainWrap}>
                {fileInfo && <FileSection />}
                {isNotAuth && <PageNotAuth />}
            </section>
        </section>
    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackbar: (vertical, horizontal, msg, color) => {
            dispatch(toggleSnackbar(vertical, horizontal, msg, color));
        },
        changeLanguageMethod: (lang) => {
            dispatch(changeLanguageMethod(lang));
        },
    };
};

const PublicSharedFile = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PublicSharedFileCompoment));

export default PublicSharedFile;
