import axios from "axios";
import Auth from "./Auth";
import {
    getErrMsg
} from "./ApiErrorCode";

export const baseURL = "/api/v4";

export const getBaseURL = () => {
    return baseURL;
};

/*
    changeURLPar()函数参数说明：
    uri:需要改变的链接
    par:需要改变或者曾加的参数名称
    par_value:需要改变或者曾加的参数值
*/
const changeURLPar = (uri, par, par_value) => {
    const pattern = par + '=([^&]*)';
    const replaceText = par + '=' + par_value;
    if (uri.match(pattern)) {//如果连接中带这个参数
        let tmp = '/\\' + par + '=[^&]*/';
        tmp = uri.replace(eval(tmp), replaceText);
        return (tmp);
    }
    else {
        if (uri.match('[?]')) {//如果链接中不带这个参数但是有其他参数
            return uri + '&' + replaceText;
        }
        else {//如果链接中没有带任何参数
            return uri + '?' + replaceText;
        }
    }
}

export const addUrlToken = (url) => {
    const token = Auth.GetLoginToken()
    return changeURLPar(url, 'token', token.slice(7))
}

const instance = axios.create({
    baseURL: getBaseURL(),
    withCredentials: true,
    crossDomain: true,
});

function AppError(message, code, error) {
    this.code = code;
    this.message = getErrMsg(code);
    // this.message += error ? " " + error : "";
    this.stack = new Error().stack;
}
AppError.prototype = Object.create(Error.prototype);
AppError.prototype.constructor = AppError;

instance.interceptors.request.use(res => {
    const accessToken = Auth.GetLoginToken()
    res.headers.authorization = `${accessToken}`
    return res
}, function (error) {
    return Promise.reject(error);
})

instance.interceptors.response.use(
    function (response) {
        response.rawData = response.data;
        response.data = response.data.data;
        window.axiosCancel = null;

        if (
            response.rawData.code !== undefined &&
            response.rawData.code !== 0 &&
            response.rawData.code !== 203
        ) {
            // 登录过期
            if (response.rawData.code === 401) {
                Auth.signout();
                window.location.href = "/login";
            }

            // 非管理员
            if (response.rawData.code === 40008) {
                window.location.href = "/home";
            }
            throw new AppError(
                response.rawData.msg,
                response.rawData.code,
                response.rawData.error
            );
        }
        return response;
    },
    function (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    Auth.signout();
                    window.location.href = "/login";
                    break;
            }
        }
        return Promise.reject(error);
    }
);

export default instance;