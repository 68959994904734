import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import App from "./App";
import cloureveApp from "./reducers";
import ErrorBoundary from "./component/Placeholder/ErrorBoundary";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { ConnectedRouter } from "connected-react-router";
import "./index.css";

// import VConsole from 'vconsole';
// if (window.location.href.indexOf("drive-dev") != -1) {
//     const vConsole = new VConsole();
// }

if (window.location.hash !== "") {
    window.location.href = window.location.hash.split("#")[1];
}

if (process.env.NODE_ENV === "production") {
    serviceWorker.unregister();
} else {
    serviceWorker.register();
}

export const history = createBrowserHistory();
let reduxEnhance = applyMiddleware(routerMiddleware(history), thunk);
if (
    process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION__
) {
    reduxEnhance = compose(reduxEnhance, window.__REDUX_DEVTOOLS_EXTENSION__());
}

export const store = createStore(cloureveApp(history), reduxEnhance);

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
    </ErrorBoundary>,
    document.getElementById("root")
);
