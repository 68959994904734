export const sizeToString = (bytes) => {
    if (bytes === 0 || bytes === "0") return "0 B";
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (bytes / Math.pow(k, i)).toFixed(1) + " " + sizes[i];
};

export const fixUrlHash = (path) => {
    return path;
};

export const setCookie = (name, value, days) => {
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    }
    document.cookie = name + "=" + (value || "") + "; path=/";
};

export const setGetParameter = (paramName, paramValue) => {
    let url = window.location.href;

    if (url.indexOf(paramName + "=") >= 0) {
        const prefix = url.substring(0, url.indexOf(paramName));
        let suffix = url.substring(url.indexOf(paramName));
        suffix = suffix.substring(suffix.indexOf("=") + 1);
        suffix =
            suffix.indexOf("&") >= 0
                ? suffix.substring(suffix.indexOf("&"))
                : "";
        url = prefix + paramName + "=" + paramValue + suffix;
    } else {
        if (url.indexOf("?") < 0) url += "?" + paramName + "=" + paramValue;
        else url += "&" + paramName + "=" + paramValue;
    }
    if (url === window.location.href) {
        return;
    }
    window.history.pushState(null, null, url);
};

export const allowSharePreview = () => {
    if (!window.isSharePage) {
        return true;
    }
    if (window.isSharePage) {
        if (window.shareInfo.allowPreview) {
            return true;
        }
        if (window.userInfo.uid === -1) {
            return false;
        }
        return true;
    }
};

export const checkGetParameters = (field) => {
    const url = window.location.href;
    if (url.indexOf("?" + field + "=") !== -1) return true;
    else if (url.indexOf("&" + field + "=") !== -1) return true;
    return false;
};

export const changeThemeColor = (color) => {
    const metaThemeColor = window.document.querySelector(
        "meta[name=theme-color]"
    );
    metaThemeColor.setAttribute("content", color);
};

export function bufferDecode(value) {
    return Uint8Array.from(atob(value), (c) => c.charCodeAt(0));
}

// ArrayBuffer to URLBase64
export function bufferEncode(value) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
}

export function pathBack(path) {
    const folders =
        path !== null
            ? path.substr(1).split("/")
            : this.props.path.substr(1).split("/");
    return "/" + folders.slice(0, folders.length - 1).join("/");
}

export function filePath(file) {
    return file.path === "/"
        ? file.path + file.name
        : file.path + "/" + file.name;
}

export function hex2bin(hex) {
    return parseInt(hex, 16).toString(2).padStart(8, "0");
}

export function pathJoin(parts, sep) {
    const separator = sep || "/";
    parts = parts.map((part, index) => {
        if (index) {
            part = part.replace(new RegExp("^" + separator), "");
        }
        if (index !== parts.length - 1) {
            part = part.replace(new RegExp(separator + "$"), "");
        }
        return part;
    });
    return parts.join(separator);
}

export function basename(path) {
    const pathList = path.split("/");
    pathList.pop();
    return pathList.join("/") === "" ? "/" : pathList.join("/");
}

export function filename(path) {
    const pathList = path.split("/");
    return pathList.pop();
}

export function randomStr(length) {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

export function getNumber(base, conditions) {
    conditions.forEach((v) => {
        if (v) {
            base++;
        }
    });
    return base;
}

export const isMac = () => {
    return navigator.platform.toUpperCase().indexOf("MAC") >= 0;
};

export function vhCheck() {
    const vh = window.innerHeight;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
}

const options = {
    cutHash: true, // replace hash `/#/{path}` to be ''
    decoder: decodeURIComponent, // self decoder or browser's
    decodeEach: false,
    all: true, // need all value whatever it is valid or not
    encode: false, // if u need? `false | encodeURLComponent | function`
    placeholder: "", // if value is invalid, so puts the `placeholder`
};

export const UrlQueryParamsParse = (str) => {
    if (!str || !str.trim().length) return null;
    else {
        if (!~str.indexOf("?") && ~str.indexOf("=") && ~str.indexOf("&")) {
            str = str
                .split("&")
                .map((t) => t.trim())
                .filter((t) => ~t.indexOf("="))
                .map((t) => (options.decodeEach ? options.decoder(t) : t));
        } else {
            str = [str];
        }
        if (str.length) {
            return str.reduce((r, c) => {
                c = c.split("=");
                if (c.length > 2) c = [c.shift(), c.join("=")];
                if (c[0]) {
                    if (c[1]) {
                        r[c[0]] = +c[1] ? +c[1] : c[1];
                    } else {
                        r[c[0]] = options.placeholder;
                    }
                }
                return r;
            }, {});
        } else {
            return null;
        }
    }
};

export const CopyValueToSystem = (value) => {
    return new Promise((resolve, reject) => {
        const input = document.createElement("input");
        document.body.appendChild(input);
        input.setAttribute("value", value);
        input.select();
        if (document.execCommand("copy")) {
            document.execCommand("copy");
            resolve();
        } else {
            reject();
        }
        document.body.removeChild(input);
    });
};

// Encoding UTF8 ⇢ base64
export const b64EncodeUnicode = (str) => {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
        encodeURIComponent(str).replace(
            /%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode("0x" + p1);
            }
        )
    );
};

// Decoding base64 ⇢ UTF8
export const b64DecodeUnicode = (str) => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
        atob(str)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
};

export const checkInsetIframe = () => {
    return window.self !== window.top; // true 说明被嵌套在iframe里了
};

export const getNavigatorLanguage = () => {
    let language = "";
    if (navigator.appName == "Netscape") {
        language = navigator.language;
    } else {
        language = navigator.userLanguage;
    }

    language = language.substr(0, 2);
    let lang = "ja";
    switch (language) {
        case "en":
            lang = "en";
            break;
        case "zh":
            lang = "zh-hant";
            break;
        case "ja":
            lang = "ja";
            break;
        default:
            break;
    }

    return lang;
};
