import {
    setSiteConfig,
    toggleSnackbar,
    enableLoadUploader,
    changeViewMethod,
} from "../actions/index";
import { fixUrlHash } from "../utils/index";
import API from "./Api";
import Auth from "./Auth";
import pathHelper from "../utils/page";

const initUserConfig = (siteConfig) => {
    if (siteConfig.user !== undefined) {
        const user = siteConfig.user;
        delete siteConfig.user;

        // 更新登录态
        Auth.authenticate(user);
    }
    if (siteConfig.user !== undefined) {
        Auth.SetUser(siteConfig.user);
    }
    return siteConfig;
};

export const InitSiteConfig = (rawStore) => {
    // 从缓存获取默认配置
    const configCache = JSON.parse(localStorage.getItem("siteConfigCache"));
    if (configCache != null) {
        rawStore.siteConfig = configCache;
    }
    // 检查是否有path参数
    const url = new URL(window.location.href);
    const c = url.searchParams.get("path");
    rawStore.navigator.path = c === null ? "/" : c;
    // 初始化用户个性配置
    rawStore.siteConfig = initUserConfig(rawStore.siteConfig);

    return rawStore;
};

export function enableUploaderLoad () {
    // 开启上传组件加载
    const user = Auth.GetUser();
    window.policyType = user !== null ? user.policy.saveType : "local";
    window.uploadConfig = user !== null ? user.policy : {};
    window.pathCache = [];
}

export async function UpdateSiteConfig (store) {
    let success = false
    return await API.get("/site/config")
        .then(function (response) {
            if (!response) {
                return
            }
            response.data = initUserConfig(response.data);
            success = true
        })
        .catch(function (error) {
            store.dispatch(
                toggleSnackbar(
                    "top",
                    "right",
                    "Unable to load site configuration:" + error.message,
                    "error"
                )
            );
            success = false
        })
        .then(function () {
            console.log('config last then....')
            enableUploaderLoad(store);
            store.dispatch(enableLoadUploader());
            return success
        });
}
