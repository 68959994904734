import React from "react";
import { Facebook } from "react-content-loader";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

import { ReactComponent as NotAuthSvg } from "../../res/img/not_auth.svg"

const useStyles = makeStyles((theme) => ({
    page: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '& > *': {
            margin: 10,
        }
    }
}));

const PageNotAuth = () => {
    const theme = useTheme();
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div className={classes.page}>
            <NotAuthSvg />
            <p>{intl.formatMessage({ id: 'not.have.permission.visit' })}</p>
        </div>
    );
}

export default PageNotAuth;
