
export const LOCAL_STORAGE_TOKEN_KEY = 'api_access_token'
export const LOCAL_STORAGE_LOGIN_INFO = 'login_info_certificates'
export const LOCAL_STORAGE_CURRENT_USER = 'login_current_user'

export const SESSION_STORAGE_TEAM_ID = 'session_storage_team_id'

const Auth = {
    isAuthenticated: false,
    authenticate(cb: any) {
        Auth.SetUser(cb);
        Auth.isAuthenticated = true;
    },
    GetLoginToken() {
        return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
    },
    SetLoginToken(newToken: any) {
        if (typeof newToken !== 'string') {
            newToken = JSON.stringify(newToken)
        }
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, newToken);
    },
    GetSessionTeamId() {
        return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_TEAM_ID) || "null")
    },
    SetSessionTeamId(teamId: string) {
        sessionStorage.setItem(SESSION_STORAGE_TEAM_ID, JSON.stringify(teamId))
    },
    GetUser() {
        return JSON.parse(localStorage.getItem("user") || "null");
    },
    SetUser(newUser: any) {
        localStorage.setItem("user", JSON.stringify(newUser));
    },
    GetCurrentLoginUser() {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_CURRENT_USER) || "null");
    },
    SetCurrentLoginUser(user: any) {
        localStorage.setItem(LOCAL_STORAGE_CURRENT_USER, JSON.stringify(user));
    },
    Check(): boolean {
        if (Auth.isAuthenticated) {
            return true;
        }
        if (localStorage.getItem("user") !== null) {
            return !Auth.GetUser().anonymous;
        }
        return false;
    },
    signout() {
        Auth.isAuthenticated = false;
        localStorage.removeItem("user");
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
        // const oldUser = Auth.GetUser();
        // oldUser.id = 0;
        // localStorage.setItem("user", JSON.stringify(oldUser));
    },
    SetPreference(key: string, value: any) {
        let preference = JSON.parse(
            localStorage.getItem("user_preference") || "{}"
        );
        preference = preference == null ? {} : preference;
        preference[key] = value;
        localStorage.setItem("user_preference", JSON.stringify(preference));
    },
    GetPreference(key: string): any | null {
        const preference = JSON.parse(
            localStorage.getItem("user_preference") || "{}"
        );
        if (preference && preference[key]) {
            return preference[key];
        }
        return null;
    },
};

export default Auth;


