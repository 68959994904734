import React, { useEffect, useState, useLayoutEffect } from "react";
import { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import {
    CircularProgress,
    makeStyles,
    LinearProgress,
    withStyles,
} from "@material-ui/core";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { toggleSnackbar, refreshTree, changeLanguageMethod } from "./actions";
import { SERVER_LANG } from "./const/global";
import Auth from "./middleware/Auth";
import { NAV_LANGUAGE } from "./config";

import { PageHeader, ConfigProvider } from "@felo/ui";

import {
    UrlQueryParamsParse,
    checkInsetIframe,
    getNavigatorLanguage,
} from "./utils/index";
import { IntlProvider } from "react-intl";
import enUS from "./locales/en-US.json";
import jaJP from "./locales/ja-JP.json";
import zhTW from "./locales/zh-TW.json";

import moment from "moment";
import "moment/locale/zh-tw";
import "moment/locale/en-nz";
import "moment/locale/ja";

import { ReactComponent as LogoIcon } from "./res/img/logo.svg";

const Home = React.lazy(() => import("./Home"));

const useStyles = makeStyles((theme) => ({
    mainWrap: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
    },
    loadingWrap: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    pageHeaderWrap: {
        padding: "5px 24px",
        backgroundColor: "#f6f8fa",
        borderBottom: "1px solid #d6dce6",
    },
}));

const messages = {
    "zh-CN": zhTW,
    "zh-TW": zhTW,
    "en-US": enUS,
    "ja-JP": jaJP,
};

const HTML_LANG = {
    "zh-TW": "zh-Hans",
    "en-US": "en",
    "ja-JP": "ja",
};

const TIME_ZONE = {
    "zh-TW": "Asia/Shanghai",
    "en-US": "America/New_York",
    "ja-JP": "Asia/Tokyo",
};

const MOMENT_LANG = {
    "zh-TW": "zh-tw",
    "en-US": "en-nz",
    "ja-JP": "ja",
};

// check clean login localStorage
const checkCleanLocalStorageUser = () => {
    const histroyQuery = new URLSearchParams(window.location.search);
    const code = histroyQuery.get("code");
    if (code) {
        localStorage.removeItem("user");
    }
};

if (process.env.NODE_ENV === "production") {
    // login every time
    // localStorage.removeItem("user");
} else {
    checkCleanLocalStorageUser();
}

const Drive = (props) => {
    const { language, changeLanguageMethod } = props;
    const classes = useStyles();
    const history = useHistory();
    const [initHome, setInitHome] = useState(false);
    const [teamId, setTeamId] = useState(0);

    const getUrlLang = () => {
        const { search } = window.location;
        const urlQuertParamsStr = search.replace(/^\?/, "");
        const queryParamsData = UrlQueryParamsParse(urlQuertParamsStr);
        if (queryParamsData && queryParamsData.lang) {
            return NAV_LANGUAGE[queryParamsData.lang] || NAV_LANGUAGE["en"];
        }
        const lang = getNavigatorLanguage();
        return NAV_LANGUAGE[lang];
    };

    const getLangByClient = () => {
        window.FeloSDK && window.FeloSDK.getLanguage();
    };

    const getLangByClientCallBack = (data) => {
        console.log("getLangByClientCallBack", data);
        if (typeof data === "string") {
            data = JSON.parse(data);
        }
        const { lang, language } = data;
        changeLanguageMethod(NAV_LANGUAGE[lang || language]);
    };

    const getLocal = () => {
        return getUrlLang() || language;
    };

    const initLocal = () => {
        window.onGetLanguage = getLangByClientCallBack;
        // iOS || Android 回调
        window.onWebViewAppeared = () => {
            console.log("onWebViewAppeared");
        };

        if (
            window.FeloSDK &&
            (window.FeloSDK.isWindows || window.FeloSDK.isMac)
        ) {
            // Android 原生端 getLanguage Bug 待修复, 先特殊处理
            if (window.FeloSDK.isAndroid) {
                const lang = getNavigatorLanguage();
                changeLanguageMethod(NAV_LANGUAGE[lang]);
            } else {
                getLangByClient();
            }
        } else {
            const language = getLocal();
            changeLanguageMethod(language);
        }
    };

    const checkTeamIsChange = (teamId) => {
        const loginInfos = Auth.GetCurrentLoginUser();
        if (!loginInfos) return false;
        if (loginInfos.team_id != teamId) {
            Auth.signout();
            history.push("/?team_id=" + teamId);
        }
    };

    const onLoginInfo = (teamInfo) => {
        console.log("current team Info", teamInfo);
        if (!teamInfo) {
            window.FeloSDK.login();
            return;
        }
        Auth.SetCurrentLoginUser(teamInfo);
        Auth.SetSessionTeamId(teamInfo.team_id);

        const lang = teamInfo.lang;
        if (lang) {
            changeLanguageMethod(SERVER_LANG[lang]);
        } else {
            initLocal();
        }

        const timezone =
            teamInfo.timezone || TIME_ZONE[language] || TIME_ZONE["ja-JP"];
        Auth.SetPreference("timeZone", timezone);

        const value = `${teamInfo.token_type} ${teamInfo.access_token}`;
        Auth.SetLoginToken(value);
        if (initHome) {
            props.refreshTree();
            history.push("/login");
        } else {
            setInitHome(true);
        }
    };

    const onLogout = () => {
        Auth.signout();
    };

    const pageHeaderHide = () => {
        return checkInsetIframe() || window.FeloSDK.isFelo;
    };

    useLayoutEffect(() => {
        const histroyQuery = new URLSearchParams(window.location.search);
        let teamId = histroyQuery.get("team_id");
        if (teamId) {
            Auth.SetSessionTeamId(teamId);
        } else {
            teamId = Auth.GetSessionTeamId();
        }
        console.log("init teamId ...", teamId);

        if (!teamId) return;

        checkTeamIsChange(teamId);
        setTeamId(Number(teamId));
    }, []);

    /**
     * Dom
     */

    const Title = () => (
        <h2 style={{ margin: 0, marginLeft: -20 }}>
            <LogoIcon style={{ verticalAlign: "middle" }} />
        </h2>
    );

    const Loading = () => {
        return (
            <section className={classes.loadingWrap}>
                <CircularProgress size={30} style={{ color: "#2A94FF" }} />
            </section>
        );
    };

    const FeloLinearProgress = withStyles((theme) => ({
        colorPrimary: {
            backgroundColor: "#c9ddf1",
        },
        bar: {
            backgroundColor: "#2A94FF",
        },
    }))(LinearProgress);

    return (
        <>
            <div className={classes.mainWrap}>
                <div
                    style={{
                        display: pageHeaderHide() ? "none" : "flex",
                        height: 60,
                        justifyContent: "center",
                    }}
                >
                    <PageHeader
                        className={classes.pageHeaderWrap}
                        appVisible={true}
                        sdk={window.FeloSDK} // 需要先给sdk设置appid
                        mode={"light"} // 颜色模式 light | dark
                        title={<Title />} // 标题
                        teamId={teamId} // 需要登录的组织, 如果已登录信息里没有该组织则会默认选中非该id的一个组织
                        anonymously={false} // 可匿名, 当未找到组织信息则会自动弹出选择帐号的弹框
                        onLoginInfo={onLoginInfo}
                        onLogout={onLogout}
                    />
                </div>

                <div style={{ flex: "auto", overflow: "auto" }}>
                    <Switch>
                        <Route exact path="">
                            <Suspense fallback={Loading()}>
                                <DndProvider backend={HTML5Backend}>
                                    {initHome ? (
                                        <Home />
                                    ) : (
                                        <FeloLinearProgress />
                                    )}
                                </DndProvider>
                            </Suspense>
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.viewUpdate.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackbar: (vertical, horizontal, msg, color) => {
            dispatch(toggleSnackbar(vertical, horizontal, msg, color));
        },
        changeLanguageMethod: (lang) => {
            dispatch(changeLanguageMethod(lang));
        },
        refreshTree: () => {
            dispatch(refreshTree());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Drive);
