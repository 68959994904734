export const imgPreviewSuffix = [
    "bmp",
    "png",
    "gif",
    "jpg",
    "jpeg",
    "svg",
    "webp",
];
export const msDocPreviewSuffix = [
    "ppt",
    "pptx",
    "pps",
    "doc",
    "docx",
    "xlsx",
    "xls",
];
export const audioPreviewSuffix = ["mp3", "ogg"];
export const videoPreviewSuffix = ["mp4", "mkv", "webm"];
export const pdfPreviewSuffix = ["pdf"];
export const editSuffix = ["md", "txt"];
export const codePreviewSuffix = {
    json: "json",
    php: "php",
    py: "python",
    bat: "bat",
    cpp: "cpp",
    c: "cpp",
    h: "cpp",
    cs: "csharp",
    css: "css",
    dockerfile: "dockerfile",
    go: "go",
    html: "html",
    ini: "ini",
    java: "java",
    js: "javascript",
    jsx: "javascript",
    less: "less",
    lua: "lua",
    sh: "shell",
    sql: "sql",
    xml: "xml",
    yaml: "yaml",
};
export const mediaType = {
    audio: ["mp3", "flac", "ape", "wav", "acc", "ogg"],
    video: ["mp4", "flv", "avi", "wmv", "mkv", "rm", "rmvb", "mov", "ogv"],
    image: ["bmp", "iff", "png", "gif", "jpg", "jpeg", "psd", "svg", "webp"],
    pdf: ["pdf"],
    word: ["doc", "docx"],
    ppt: ["ppt", "pptx"],
    excel: ["xls", "xlsx", "csv"],
    text: ["txt", "md", "html"],
    torrent: ["torrent"],
    zip: ["zip", "gz", "tar", "rar", "7z"],
    excute: ["exe"],
    android: ["apk"],
    php: ["php"],
    go: ["go"],
    python: ["py"],
    cpp: ["cpp"],
    c: ["c"],
    js: ["js", "jsx"],
};
export const policyTypeMap = {
    local: "本机",
    remote: "从机",
    qiniu: "七牛",
    upyun: "又拍云",
    oss: "阿里云 OSS",
    cos: "腾讯云",
    onedrive: "OneDrive",
    s3: "Amazon S3",
};
export const isPreviewable = (name) => {
    if (!name) {
        return false;
    }
    const suffix = name.split(".").pop().toLowerCase();
    if (imgPreviewSuffix.indexOf(suffix) !== -1) {
        return "img";
    } else if (msDocPreviewSuffix.indexOf(suffix) !== -1) {
        return "msDoc";
    } else if (audioPreviewSuffix.indexOf(suffix) !== -1) {
        return "audio";
    } else if (videoPreviewSuffix.indexOf(suffix) !== -1) {
        return "video";
    } else if (editSuffix.indexOf(suffix) !== -1) {
        return "edit";
    } else if (pdfPreviewSuffix.indexOf(suffix) !== -1) {
        return "pdf";
    } else if (Object.keys(codePreviewSuffix).indexOf(suffix) !== -1) {
        return "code";
    }
    return false;
};
export const isCloudDocument = (type) => {
    return type === "cloudDocument" || type === "sheet";
};
export const isTorrent = (name) => {
    const suffix = name.split(".").pop().toLowerCase();
    if (mediaType.torrent.indexOf(suffix) !== -1) {
        return true;
    }
    return false;
};
export const isWord = (name) => {
    const suffix = name.split(".").pop().toLowerCase();
    if (mediaType.word.indexOf(suffix) !== -1) {
        return true;
    }
    return false;
};

export const isCompressFile = (name) => {
    const suffix = name.split(".").pop().toLowerCase();
    return suffix === "zip";
};

export const isDir = (file) => {
    if (!file) {
        return;
    }
    return file.type === "dir";
};

export const sortFileListDate = (a, b) => {
    const dateA = new Date(a.UpdatedAt).getTime();
    const dateB = new Date(b.UpdatedAt).getTime();
    return dateA - dateB;
};

const taskStatus = ["排队中", "处理中", "失败", "取消", "已完成"];
const taskType = ["压缩", "解压缩", "文件中转", "导入外部目录", "存储策略转移"];
const taskProgress = [
    "等待中",
    "压缩中",
    "解压缩中",
    "下载中",
    "转存中",
    "索引中",
    "插入中",
];

export const getTaskStatus = (status) => {
    return taskStatus[status];
};

export const getTaskType = (status) => {
    return taskType[status];
};

export const getTaskProgress = (type, status) => {
    if (type === 2) {
        return "已完成 " + (status + 1) + " 个文件";
    }
    return taskProgress[status];
};

export const reportReasons = [
    "色情信息",
    "包含病毒",
    "侵权",
    "不恰当的言论",
    "其他",
];

export const LanguageArr = ["zh-CN", "zh-TW", "en-US", "ja-JP"];

export const SHARE_AUTH = {
    READ: 1,
    EDIT: 2,
    OWNER: 3,
    REMOVE: -1,
};

export const SHARE_AUTH_TYPE = {
    ORG_READ: 1, // 组织内成员通过链接可以阅读
    ORG_EDIT: 2, // 组织内成员通过链接可以编辑
    ALL_READ: 3, // 所有人通过链接可以阅读
    ALL_EDIT: 4, // 所有人通过链接可以编辑
};

export const SHARE_TYPE = {
    PERSION: 1, // 分享给人
    SESSION: 2, // 分享给会话
    DEPT: 3, // 分享给部门
    FOLDER: 4, // 分享给文件夹
};

export const TREE_NODE_ID = {
    TopPage: "top",
    MySpacePage: "home",
    PublicPage: "public",
    SharedPage: "shared",
    FavouritesPage: "favourites",
    TrashPage: "trash",
};

export const NAV_LANGUAGE = {
    "zh-hans": "zh-CN",
    "zh-hant": "zh-TW",
    en: "en-US",
    ja: "ja-JP",
};
