import { pathBack } from ".";

const statusHelper = {
    isHomePage(path) {
        return path === "/home" || path === "/public" || path === "/shared";
    },
    isHomePagePath(path) {
        return path && path.startsWith("/home");
    },
    isPublicPage(path) {
        return path && path.startsWith("/public");
    },
    isSharedPage(path) {
        return path && path.startsWith("/shared");
    },
    isSharePage(path) {
        return path && path.startsWith("/s/");
    },
    isAdminPage(path) {
        return path && path.startsWith("/admin");
    },
    isLoginPage(path) {
        return path && path.startsWith("/login");
    },
    isTopPage(path) {
        return path && path.startsWith("/top");
    },
    isFavouritesPage(path) {
        return path && path.startsWith("/favourites");
    },
    isTrashPage(path) {
        return path && path.startsWith("/trash");
    },
    isMobile() {
        return window.innerWidth < 600;
    },
};

export default statusHelper;
