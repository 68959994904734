import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import Auth from "../middleware/Auth";
dayjs.extend(utc);
dayjs.extend(timezone);

const defaultTimeZone = "Asia/Tokyo";
// const preferTimeZone = Auth.GetPreference("timeZone");
const preferTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone // 客户端本地时区
export let timeZone = preferTimeZone ? preferTimeZone : defaultTimeZone;

export function refreshTimeZone() {
    timeZone = Auth.GetPreference("timeZone");
    timeZone = timeZone ? timeZone : defaultTimeZone;
}

export function getTodayTime(format) {
    return dayjs().format(format)
}

export function getTomorrowTime(format) {
    const day = new Date()
    day.setDate(day.getDate() + 1)
    return dayjs(day).format(format)
}

export function formatLocalTime(time, format) {
    return dayjs(time).tz(timeZone).format(format);
}

export function formatToUTCTime(time) {
    return dayjs(time).utc().format()
}

export function validateTimeZone(name) {
    try {
        dayjs().tz(name).format();
    } catch (e) {
        return false;
    }
    return true;
}
