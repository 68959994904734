import React, { useEffect, useState, useLayoutEffect } from "react";
import { Suspense } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import {
    CircularProgress,
    makeStyles,
    LinearProgress,
    withStyles,
} from "@material-ui/core";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { toggleSnackbar, refreshTree } from "./actions";
import Auth from "./middleware/Auth";

import { PageHeader, ConfigProvider } from "@felo/ui";

import { UrlQueryParamsParse, checkInsetIframe } from "./utils/index";
import { IntlProvider } from "react-intl";
import enUS from "./locales/en-US.json";
import jaJP from "./locales/ja-JP.json";
import zhTW from "./locales/zh-TW.json";

import moment from "moment";
import "moment/locale/zh-tw";
import "moment/locale/en-nz";
import "moment/locale/ja";

import PublicSharedFile from "./component/Share/PublicSharedFile";
import Drive from "./Drive";

const Home = React.lazy(() => import("./Home"));

const useStyles = makeStyles((theme) => ({
    mainWrap: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
    },
    loadingWrap: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const messages = {
    "zh-CN": zhTW,
    "zh-TW": zhTW,
    "en-US": enUS,
    "ja-JP": jaJP,
};

const HTML_LANG = {
    "zh-TW": "zh-Hans",
    "en-US": "en",
    "ja-JP": "ja",
};

const MOMENT_LANG = {
    "zh-TW": "zh-tw",
    "en-US": "en-nz",
    "ja-JP": "ja",
};

const App = (props) => {
    const { language } = props;
    const { path } = useRouteMatch();
    const classes = useStyles();

    useEffect(() => {
        window.react_intl_local = language;
        moment.locale(MOMENT_LANG[language]);

        document
            .getElementsByTagName("html")[0]
            .setAttribute("lang", HTML_LANG[language] || HTML_LANG["ja-JP"]);
    }, [language]);

    useLayoutEffect(() => {
        const APPID = {
            dev: "ydCDD0A2ADBACD417799CDC6572916E0F1",
            pro: "yd03A23FA124BB4A14BC9919CC37D89E9F",
        };
        const sdkConfig = {
            appId: process.env.SDK_ENV === "production" ? APPID.pro : APPID.dev,
            mode: process.env.SDK_ENV === "production" ? "pro" : "dev",
        };
        console.log("process.env.SDK_ENV", process.env.SDK_ENV);
        window.FeloSDK.config(sdkConfig);
    }, []);

    const Loading = () => {
        return (
            <section className={classes.loadingWrap}>
                <CircularProgress size={30} style={{ color: "#2A94FF" }} />
            </section>
        );
    };

    return (
        <>
            {language ? (
                <IntlProvider locale={language} messages={messages[language]}>
                    <ConfigProvider lang={language}>
                        <div className={classes.mainWrap}>
                            <Switch>
                                <Route exact path={`${path}file/:id`}>
                                    <PublicSharedFile />
                                </Route>
                                <Route path="">
                                    <Drive />
                                </Route>
                            </Switch>
                        </div>
                    </ConfigProvider>
                </IntlProvider>
            ) : (
                Loading()
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.viewUpdate.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackbar: (vertical, horizontal, msg, color) => {
            dispatch(toggleSnackbar(vertical, horizontal, msg, color));
        },
        refreshTree: () => {
            dispatch(refreshTree());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
