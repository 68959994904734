import { AnyAction } from "redux";
import Auth, { LOCAL_STORAGE_LOGIN_INFO } from "../../middleware/Auth";

declare global {
    interface Window {
        shareKey: any;
    }
}

export const NAV_LANGUAGE = {
    "zh-hans": "zh-CN",
    "zh-hant": "zh-TW",
    en: "en-US",
    ja: "ja-JP",
} as any;

export interface ViewUpdateState {
    isLogin: boolean;
    loadUploader: boolean;
    open: boolean;
    explorerViewMethod: string;
    sortMethod:
        | "sizePos"
        | "sizeRes"
        | "namePos"
        | "nameRev"
        | "timePos"
        | "timeRev";
    subTitle: string | null;
    contextType: string;
    contextOpen: boolean;
    menuOpen: boolean;
    navigatorLoading: boolean;
    navigatorError: boolean;
    navigatorErrorMsg: string | null;
    modalsLoading: boolean;
    storageRefresh: boolean;
    userPopoverAnchorEl: any;
    shareUserPopoverAnchorEl: any;
    modals: {
        cloneFile: boolean;
        addFile: boolean;
        searchFile: boolean;
        createNewFolder: string;
        createNewSharedFolder: boolean;
        createNewFile: boolean;
        createNewDocument: boolean;
        createNewSheet: boolean;
        transitionToDoc: boolean;
        quickAccessAdd: boolean;
        quickAccessRemove: boolean;
        favoritesAdd: boolean;
        favoritesRemove: boolean;
        trashRestore: boolean;
        trashDelete: boolean;
        rename: boolean;
        move: boolean;
        remove: boolean;
        share: boolean;
        feloShare: boolean;
        feloSelectOrg: boolean;
        feloSelectOrgSubmit: any;
        feloOpenOfficeDoc: boolean;
        music: boolean;
        remoteDownload: boolean;
        getSource: boolean;
        copy: boolean;
        resave: boolean;
        compress: boolean;
        decompress: boolean;
        relocate: boolean;
        loading: boolean;
        loadingText: string;
    };
    snackbar: {
        toggle: boolean;
        vertical: string;
        horizontal: string;
        msg: string;
        color: string;
    };
    language: string;
    treeRefreshPath: string;
    treeRefreshPathType: string;
    moveRefreshParam: any;
    refreshQuickAccess: boolean;
    refreshRecent: boolean;
    refreshFavorites: boolean;
    refreshTrash: boolean;
    refreshTree: boolean;
    certificates: any[];
}
export const initState: ViewUpdateState = {
    // 是否登录
    isLogin: Auth.Check(),
    loadUploader: false,
    open: true,
    explorerViewMethod: "list", // list | icon | smallIcon
    sortMethod: "timePos",
    subTitle: null,
    contextType: "none",
    contextOpen: false,
    menuOpen: false,
    navigatorLoading: true,
    navigatorError: false,
    navigatorErrorMsg: null,
    modalsLoading: false,
    storageRefresh: false,
    userPopoverAnchorEl: null,
    shareUserPopoverAnchorEl: null,
    modals: {
        cloneFile: false,
        addFile: false,
        searchFile: false,
        createNewFolder: "",
        createNewSharedFolder: false,
        createNewFile: false,
        createNewDocument: false,
        createNewSheet: false,
        transitionToDoc: false,
        quickAccessAdd: false,
        quickAccessRemove: false,
        favoritesAdd: false,
        favoritesRemove: false,
        trashRestore: false,
        trashDelete: false,
        rename: false,
        move: false,
        remove: false,
        share: false,
        feloShare: false,
        feloSelectOrg: false,
        feloSelectOrgSubmit: null,
        feloOpenOfficeDoc: false,
        music: false,
        remoteDownload: false,
        getSource: false,
        copy: false,
        resave: false,
        compress: false,
        relocate: false,
        decompress: false,
        loading: false,
        loadingText: "",
    },
    snackbar: {
        toggle: false,
        vertical: "top",
        horizontal: "center",
        msg: "",
        color: "",
    },
    language:
        localStorage.getItem("FeloDrivelanguage") ||
        NAV_LANGUAGE[navigator.language] ||
        navigator.language,
    treeRefreshPath: "",
    treeRefreshPathType: "",
    moveRefreshParam: null,
    refreshQuickAccess: false,
    refreshRecent: false,
    refreshFavorites: false,
    refreshTrash: false,
    refreshTree: false,
    certificates: JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_LOGIN_INFO) || "[]"
    ),
};
const viewUpdate = (state: ViewUpdateState = initState, action: AnyAction) => {
    switch (action.type) {
        case "DRAWER_TOGGLE":
            return Object.assign({}, state, {
                open: action.open,
            });
        case "CHANGE_VIEW_METHOD":
            return Object.assign({}, state, {
                explorerViewMethod: action.method,
            });
        case "SET_NAVIGATOR_LOADING_STATUE":
            return Object.assign({}, state, {
                navigatorLoading: action.status,
            });
        case "SET_NAVIGATOR_ERROR":
            return Object.assign({}, state, {
                navigatorError: action.status,
                navigatorErrorMsg: action.msg,
            });
        case "OPEN_ADD_FILE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    addFile: true,
                }),
                contextOpen: false,
            });
        case "OPEN_SEARCH_FILE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    searchFile: true,
                }),
                contextOpen: false,
            });
        case "OPEN_CLONE_FILE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    cloneFile: true,
                }),
                contextOpen: false,
            });
        case "OPEN_CREATE_FOLDER_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    createNewFolder: action.navigatorType,
                }),
                contextOpen: false,
            });
        case "OPEN_CREATE_SHARED_FOLDER_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    createNewSharedFolder: true,
                }),
            });
        case "OPEN_CREATE_FILE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    createNewFile: true,
                }),
                contextOpen: false,
            });
        case "OPEN_CREATE_DOCUMENT_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    createNewDocument: true,
                }),
                contextOpen: false,
            });
        case "OPEN_CREATE_SHEET_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    createNewSheet: true,
                }),
                contextOpen: false,
            });
        case "OPEN_TRANSITION_TO_DOC_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    transitionToDoc: true,
                }),
                contextOpen: false,
            });
        case "OPEN_FAVORITES_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    favoritesAdd: true,
                }),
                contextOpen: false,
            });
        case "OPEN_ADD_QUICK_ACCESS_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    quickAccessAdd: true,
                }),
                contextOpen: false,
            });
        case "OPEN_REMOVE_QUICK_ACCESS_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    quickAccessRemove: true,
                }),
                contextOpen: false,
            });
        case "OPEN_FAVORITES_REMOVE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    favoritesRemove: true,
                }),
                contextOpen: false,
            });
        case "OPEN_TRASH_RESTORE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    trashRestore: true,
                }),
                contextOpen: false,
            });
        case "OPEN_TRASH_DELETE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    trashDelete: true,
                }),
                contextOpen: false,
            });
        case "OPEN_RENAME_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    rename: true,
                }),
                contextOpen: false,
            });
        case "OPEN_REMOVE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    remove: true,
                }),
                contextOpen: false,
            });
        case "OPEN_MOVE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    move: true,
                }),
                contextOpen: false,
            });
        case "OPEN_RESAVE_DIALOG":
            window.shareKey = action.key;
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    resave: true,
                }),
                contextOpen: false,
            });
        case "SET_USER_POPOVER":
            return Object.assign({}, state, {
                userPopoverAnchorEl: action.anchor,
            });
        case "SET_SHARE_USER_POPOVER":
            return Object.assign({}, state, {
                shareUserPopoverAnchorEl: action.anchor,
            });
        case "OPEN_SHARE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    share: true,
                }),
                contextOpen: false,
            });
        case "OPEN_FELOSHARE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    feloShare: true,
                }),
                contextOpen: false,
            });
        case "OPEN_FELOSElECTORG_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    feloSelectOrg: true,
                    feloSelectOrgSubmit: action.submit,
                }),
                contextOpen: false,
            });
        case "CLOSE_FELOSElECTORG_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    feloSelectOrg: false,
                    feloSelectOrgSubmit: null,
                }),
            });
        case "OPEN_FELOOPENOFFICEDOC_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    feloOpenOfficeDoc: true,
                }),
            });
        case "OPEN_MUSIC_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    music: true,
                }),
                contextOpen: false,
            });
        case "OPEN_REMOTE_DOWNLOAD_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    remoteDownload: true,
                }),
                contextOpen: false,
            });
        case "OPEN_DECOMPRESS_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    decompress: true,
                }),
                contextOpen: false,
            });
        case "OPEN_COMPRESS_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    compress: true,
                }),
                contextOpen: false,
            });
        case "OPEN_GET_SOURCE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    getSource: true,
                }),
                contextOpen: false,
            });
        case "OPEN_RELOCATE_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    relocate: true,
                }),
                contextOpen: false,
            });
        case "OPEN_COPY_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    copy: true,
                }),
                contextOpen: false,
            });
        case "OPEN_LOADING_DIALOG":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    loading: true,
                    loadingText: action.text,
                }),
                contextOpen: false,
            });
        case "CLOSE_CONTEXT_MENU":
            return Object.assign({}, state, {
                contextOpen: false,
            });
        case "CLOSE_ALL_MODALS":
            return Object.assign({}, state, {
                modals: Object.assign({}, state.modals, {
                    cloneFile: false,
                    addFile: false,
                    searchFile: false,
                    createNewFolder: "",
                    createNewSharedFolder: false,
                    createNewFile: false,
                    createNewDocument: false,
                    createNewSheet: false,
                    transitionToDoc: false,
                    quickAccessAdd: false,
                    quickAccessRemove: false,
                    favoritesAdd: false,
                    favoritesRemove: false,
                    trashRestore: false,
                    trashDelete: false,
                    rename: false,
                    move: false,
                    remove: false,
                    share: false,
                    feloShare: false,
                    feloSelectOrg: false,
                    feloSelectOrgSubmit: null,
                    feloOpenOfficeDoc: false,
                    music: false,
                    remoteDownload: false,
                    getSource: false,
                    resave: false,
                    copy: false,
                    loading: false,
                    relocate: false,
                    compress: false,
                    decompress: false,
                }),
            });
        case "TOGGLE_SNACKBAR":
            return Object.assign({}, state, {
                snackbar: {
                    toggle: !state.snackbar.toggle,
                    vertical: action.vertical,
                    horizontal: action.horizontal,
                    msg: action.msg,
                    color: action.color,
                },
            });
        case "SET_MODALS_LOADING":
            return Object.assign({}, state, {
                modalsLoading: action.status,
            });
        case "SET_SESSION_STATUS":
            return {
                ...state,
                isLogin: action.status,
            };
        case "ENABLE_LOAD_UPLOADER":
            return Object.assign({}, state, {
                loadUploader: true,
            });
        case "REFRESH_STORAGE":
            return Object.assign({}, state, {
                storageRefresh: !state.storageRefresh,
            });
        case "SEARCH_MY_FILE":
            return Object.assign({}, state, {
                contextOpen: false,
                navigatorError: false,
                navigatorLoading: true,
            });
        case "CHANGE_CONTEXT_MENU":
            if (state.contextOpen && action.open) {
                return Object.assign({}, state);
            }
            return Object.assign({}, state, {
                contextOpen: action.open,
                contextType: action.menuType,
            });
        case "SET_SUBTITLE":
            return Object.assign({}, state, {
                subTitle: action.title,
            });
        case "SET_SORT_METHOD":
            return {
                ...state,
                sortMethod: action.method,
            };
        case "SET_NAVIGATOR":
            return {
                ...state,
                contextOpen: false,
                navigatorError: false,
                navigatorLoading: action.navigatorLoading,
            };
        case "SET_NAVIGATOR_LOADING":
            return {
                ...state,
                navigatorLoading: action.navigatorLoading,
            };
        case "CHANGE_LANGUAGE":
            return Object.assign({}, state, {
                language: action.language,
            });
        case "SET_TREEREFRESHPATH":
            return Object.assign({}, state, {
                treeRefreshPath: action.path,
                treeRefreshPathType: action.pathType,
            });
        case "SET_MOVEREfRESHPARAM":
            return Object.assign({}, state, {
                moveRefreshParam: action.param,
            });
        case "REFRESH_RECENT_LIST":
            return Object.assign({}, state, {
                refreshRecent: !state.refreshRecent,
            });
        case "REFRESH_QUICK_ACCESS_LIST":
            return Object.assign({}, state, {
                refreshQuickAccess: !state.refreshQuickAccess,
            });
        case "REFRESH_FAVORITES_LIST":
            return Object.assign({}, state, {
                refreshFavorites: !state.refreshFavorites,
            });
        case "REFRESH_TRASH_LIST":
            return Object.assign({}, state, {
                refreshTrash: !state.refreshTrash,
            });
        case "REFRESH_TRASH_TREE":
            return Object.assign({}, state, {
                refreshTree: !state.refreshTree,
            });
        case "SET_CERTIFICATES":
            return Object.assign({}, state, {
                certificates: action.certificates,
            });
        default:
            return state;
    }
};

export default viewUpdate;
